export const environment = {
  production: false,

  // server ip use
  apiURL:'https://naccollection.vsolv.co.in/api/',
  kvbbeURL:'https://naccollection.vsolv.co.in/nac_main/',
  
  pprFEURL:'http://3.108.200.134:9085/',
  nacFEURL:'http://3.108.200.134:9089/',
  faFEURL:'http://3.108.200.134:9086/',
  prsFEURL:'http://3.108.200.134:9096/',
  gstFEURL:'http://3.108.200.134:9094/',
  cibilFEURL:'http://3.108.200.134:9095/',
  lprFEURL:'http://3.108.200.134:9100/',
  

  // connect backend ips
  // apiURL: "http://192.168.5.160:8008/",
  // kvbbeURL: "http://192.168.5.160:8004/",

  
  // local ip builds
  // apiURL: "http://127.0.0.1:8002/",
  // kvbbeURL: "http://127.0.0.1:8001/",


  // pprURL: 'http://3.108.200.134:8190/',
  // faURL:'http://3.108.200.134:8191/',
  // lprURL:'http://3.108.200.134:8214/',

  isSkipLocationChange: true,
};

