import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { ProjectComponent } from './project/project.component';
import { LoginComponent } from './login/login.component';
import { CanActivateGuardService } from './can-activate-guard.service';
import { VendorRenewalReportComponent } from './vendor-renewal-report/vendor-renewal-report.component';

const routes: Routes = [

  { path: 'login', component: LoginComponent },
  { path: 'contact', component: ContactComponent, canActivate:[CanActivateGuardService] },
  { path: 'kvb', component: AboutComponent, canActivate:[CanActivateGuardService] },
  { path: 'project', component: ProjectComponent, canActivate:[CanActivateGuardService] },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'VendorRenewalReport', component: VendorRenewalReportComponent, canActivate: [CanActivateGuardService] },
  { path:'crm',loadChildren:()=> import("./product-module/product-module.module").then(m=>m.ProductModuleModule)},
  { path:'crmreport',loadChildren: () => import ("./crmreport/reports.module").then(m=>m.ReportsModule)}

];

@NgModule({
  
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled',useHash:true }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
