<body>
  <div id="app-navigation">
    <div id="navigation-left" *ngIf="sharedService.isLoggedin === true">
      <div class="app-logo">
        <img src="./assets/images/Nimbus.png" width="60px" />&nbsp;
        <p *ngIf="sharedService.isSideNav === false">Collection</p>
      </div>

      <div *ngIf="menuList" class="navigation-menus scrollable-menu">
        <p
          *ngFor="let menu of menuList; let i = index"
          title="{{ menu.name }}"
          (click)="myModuleFunctions(menu, i)"
          [ngClass]="{
            'menu-inactive': true,
            'menu-active': navename === menu.name
          }"
        >
          <i class="fa-solid fa-sitemap"></i>&nbsp;<b
            *ngIf="sharedService.isSideNav === false"
            >{{ menu.name }}</b
          >
        </p>

        <!-- <i [matMenuTriggerFor]="menu" class="fa-solid fa-ellipsis cursor"></i>
        <mat-menu #menu="matMenu">
          <div *ngIf="menuList">
            <button
              style="padding: 0 16px"
              mat-menu-item
              *ngFor="let menu of menuList.slice(7); let i = inde"
              (click)="myModuleFunction(menu, i)"
            >
              <a style="font-size: 14px" title="{{ menu.name }}">
                {{ menu.name }}
              </a>
            </button>
          </div>
        </mat-menu> -->
      </div>
    </div>

    <div class="navigation-right">
      <!-- <select
        name=""
        class="form-control"
        #selectedLang
        (change)="swicthLang(selectedLang.value)"
      >
        <option
          *ngFor="let language of translate.getLangs()"
          [value]="language"
          [selected]="language === translate.currentLang"
        >
          {{ language }}
        </option>
      </select> -->

      <!-- <p *ngIf="lastPing">
        Last keepalive ping <b>{{ lastPing }}</b>
      </p>
      <div (click)="notifications()">
        <span
          ><i style="font-size: 20px" class="fa-solid fa-list-check cursor"></i
        ></span>
      </div>
      <span class="badge-btn badge">{{ notificationvalue.length }}</span> -->
      <div *ngIf="sharedService.isLoggedin === true" class="navright-top">
        <div style="display: flex">
          <mat-icon (click)="openNav()" style="margin: 8px">menu</mat-icon>
          <b style="margin-top: 7px">{{ navename }}</b>
        </div>
        <div style="display: flex; gap: 20px">
          <span
            [ngStyle]="{ color: adcolor }"
            style="margin-top: 6px; font-size: 18px"
            >{{ idleState }}</span
          >
          <!-- <span>{{ sharedService.enti }}</span> -->
          <span style="margin-top: 6px">{{ sharedService.Loginname }}</span>
          <span id="UserNameContainer" style="margin-top: 6px; font-size: 18px"
            >({{ sharedService.entity_Name }})</span
          >
          <img
            src="https://cdn-icons-png.flaticon.com/512/152/152360.png"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalentity"
            (click)="onClickSwitchIcon()"
            style="
              width: 25px;
              cursor: pointer;
              margin-top: 6px;
              font-size: 8px;
            "
          />
          <!-- <img src="./assets/images/logouts" width="30px" height="20px" (click)="logout()" /> -->
          <span
            class="material-icons"
            (click)="logout()"
            style="margin-top: 6px; font-size: 30px"
          >
            logout
          </span>
          <!-- <i class="fa-solid fa-right-from-bracket" (click)="logout()" style="margin: 10px;"></i> -->
        </div>

        <!-- <p [matMenuTriggerFor]="profileMenu">
          <i class="fa-solid fa-circle-user" style="font-size: 18px"></i>
        </p>
        <mat-menu #profileMenu="matMenu">
          <p mat-menu-item>{{ sharedService.Loginname }}</p>
          <p mat-menu-item (click)="logout()">
          </p>
        </mat-menu> -->
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</body>

<!-- ((Commented Lines)) -->
<!-- <p
          style="margin-bottom: 0"
          *ngIf="
            headerName != 'vendorView' &&
            headerName != 'branchView' &&
            headerName != 'REMS'
          "
          (click)="openNav()"
        >
          <i style="margin-right: 10px" class="fa-solid fa-bars"></i>
          <span>{{ CommonTitle }}</span>
        </p> -->
<!-- <div class="dropdown">
            <p data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa-solid fa-circle-user"></i>
            </p>
            <ul class="dropdown-menu">
              <li class="dropdown-item">
                <div
                  style="
                    font-size: 14px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;
                  "
                >
                  <p>{{ sharedService.Loginname }}</p>
                  <span [ngStyle]="{ color: adcolor }">
                    {{ idleState }}
                  </span>
                  <p
                    (click)="logout()"
                    style="display: flex; align-items: center; gap: 10px"
                  >
                    <i
                      style="font-size: 16px"
                      class="fa-solid fa-right-from-bracket"
                    ></i>
                    <span>Logout</span>
                  </p>
                </div>
              </li>
            </ul>
          </div> -->
<!-- <div class="dropdown"> -->
<!-- <p
                (click)="masterData()"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span *ngIf="sharedService.isSideNav === false" title="Master"
                  ><i class="fa fa-bar-chart-o" title="Master"></i>&nbsp;
                  {{ "Master" | translate }}</span
                >
              </p> -->
<!-- <ul class="dropdown-menu">
                <li> -->
<!-- <div
              class="dropdown-item"
              *ngFor="let modrow of sharedService.masterList; let i = index"
            > -->
<!-- [ngClass]="{ parent_selected: checkIfCardIsClicked(i) }" -->

<!-- <div class="dropdown">
              <p
                (click)="homes()"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span *ngIf="sharedService.isSideNav === false" title="Home "
                  ><i class="fa fa-home" title="Home "></i>&nbsp;{{
                    "Home" | translate
                  }}</span
                >
              </p>
              <ul class="dropdown-menu">
                <li> -->
<!-- <div
              class="dropdown-item"
              *ngFor="
                let modrow of sharedService.transactionList;
                let i = index
              "
              #itemElement
            > -->
<!-- [ngClass]="{ parent_selected: checkIfCardIsClicked(i) }" -->
<!-- <p
              *ngFor="
                let modrow of sharedService.transactionList;
                let i = index
              "
              title="{{ modrow.name }} "
              (click)="moduleclick(modrow, i)"
            >
              <i class="{{ modrow.logo }}"></i>&nbsp;
              <span
                class="sideNav-title"
                *ngIf="sharedService.isSideNav === false"
                >{{ modrow.name }}</span
              >
            </p>
            <p
              *ngFor="let modrow of sharedService.masterList; let i = index"
              title="{{ modrow.name }} "
              (click)="myModuleFunction(modrow, i)"
            >
              <i class="{{ modrow.logo }}"></i>
              <span
                class="sideNav-title"
                *ngIf="sharedService.isSideNav === false"
                >{{ modrow.name }}</span
              >
            </p> -->

<!-- <p
              *ngFor="let menus of menuList; let i = index"
              title="{{ menus.name }}"
              (click)="myModuleFunction(menus, i)"
            >
              <span class="sideNav-title">{{ menus.name }}</span>
            </p>

            <div class="dropdown">
              <p data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-solid fa-ellipsis"></i>
              </p>
              <ul class="dropdown-menu">
                <li class="dropdown-item"></li>
              </ul>
            </div> -->






<div id="mymodal" class="modal" [style.display]="showModal ? 'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <p class="modal-title">Vsolv CRM</p> -->
      </div>
      <div class="modal-body">
        <div>
          <p>Your session will expire in {{ idleState }}.</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="logout()">
          Logout
        </button>
        <button
          type="button"
          ngbAutofocus
          class="btnsave"
          (click)="continue()"
        >
          Continue Session
        </button>
      </div>
    </div>
  </div>
</div>

<!-- mobile updation starts-->
<div
  class="modal fade"
  id="mobileupdation"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Employee"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <!-- <h4 class="modal-title  w-100 font-weight-bold" id="Employee">Employee Personal Info</h4> -->
        <h4>
          <p>EMPLOYEE PERSONAL INFO</p>
        </h4>
        <button
          type="button"
          #closebutton
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="mobileupdationform" autocomplete="off">
          <div class="col-md-12 row">
            <div class="col-md-4">
              <mat-form-field appearance="outline">
                <mat-label> Name</mat-label>
                <input matInput [readonly]="true" formControlName="name" />
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field appearance="outline">
                <mat-label>Code</mat-label>
                <input matInput [readonly]="true" formControlName="code" />
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field appearance="outline">
                <mat-label>Mobile</mat-label>
                <input type="text" matInput formControlName="mobile_number" />
              </mat-form-field>
            </div>

            <div class="col-md-8" class="point" *ngIf="otpflag">
              <p *ngIf="otpflag">
                Please enter the 8-digit verification <br />
                code we sent via SMS:(we want to make sure it's you before
                update)
              </p>
            </div>

            <div class="col-md-4" *ngIf="otpflag">
              <mat-form-field appearance="outline">
                <mat-label>OTP</mat-label>
                <input
                  type="text"
                  autocomplete="off"
                  numbersOnly
                  matInput
                  formControlName="otp"
                />
              </mat-form-field>
              <p class="count">Did not Recieve in {{ count }}s?</p>
              <p
                class="count"
                *ngIf="count == 0"
                style="text-align: right; color: blue"
                (click)="submitForm()"
              >
                <a>Resend OTP</a>
              </p>
            </div>
          </div>

          <div class="text-right">
            <button
              class="btn btn-outline-success"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#mobileupdation"
            >
              Cancel
            </button>
            &nbsp;&nbsp;
            <button
              *ngIf="!otpflag"
              class="btn btn-outline-primary"
              type="submit"
              (click)="submitForm()"
            >
              Update
            </button>
            <button
              *ngIf="otpflag"
              class="btn btn-outline-primary"
              type="submit"
              (click)="updatemobile()"
            >
              Verify
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- mobile updation ends -->

<ngx-spinner
  bdColor="rgba(255, 255, 255, 0.8)"
  color="#fff"
  size="medium"
  type="custom"
>
  <!-- <p style="font-size: 20px; color: white">Loading...</p> -->
  <app-spinner></app-spinner>
</ngx-spinner>

<!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen] = "true" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner> -->

<!--doocty phone call-->
<div
  cdkDrag
  class="iframe-pannel"
  [ngStyle]="{ display: hideDooctiDiv ? 'block' : 'none' }"
>
  <div class="iframe-head">
    <p class="iframe-head-title">Calls</p>
    <div>
      <p class="d-flex gap-3">
        <i
          class="fa-solid fa-down-left-and-up-right-to-center"
          (click)="callMini()"
        ></i>
        <i class="fa-solid fa-circle-xmark" (click)="closeDoocti()"></i>
      </p>
    </div>
  </div>
  <div
    [ngStyle]="{ display: hideDoocti ? 'block' : 'none' }"
    class="iframe-div"
  >
    <iframe
      class="iframe"
      id="myIframe"
      #myIframe
      frameborder="0"
      scrolling="no"
      loading="lazy"
      allow="camera *; geolocation *; microphone *; autoplay *; display-capture *"
      src="https://crm-app.doocti.com"
      allowfullscreen
    ></iframe>
  </div>
</div>

<div class="reminder" *ngIf="notificationpopup" style="margin-top: 50px">
  <!-- <header>
    <p class="title">Tasks</p>
    <i
      class="fa-solid fa-circle-xmark cursor"
      (click)="closenotification()"
    ></i>
  </header> -->
  <div class="content">
    <div *ngIf="notificationvalue.length === 0">
      <p style="text-align: center">No Tasks Found...</p>
    </div>
    <div class="list" *ngFor="let data of notificationvalue; let i = index">
      <div class="list-content">
        <h4>
          <i class="fa-solid fa-list-check"></i
          ><span matTooltipPosition="right" class="cursor">
            Task {{ i + 1 }}</span
          >
        </h4>
        <div (click)="routetotask(data)" class="cursor">
          <span
            style="
              font-size: 15px;
              font-weight: bold;
              color: #1665db;
              text-decoration: underline;
            "
            >{{ data.task_name }}</span
          >&nbsp;
          <span>{{ data.period | date : "MMM d, y, h:mm a" }}</span>
        </div>
      </div>
    </div>
  </div>
  <footer *ngIf="notificationvalue.length >= 4">
    <button>View all</button>
  </footer>
</div>

<div class="reminder-bar" *ngIf="showPopup" style="margin-top: 50px">
  <header>
    <div>
      <i class="fa-solid fa-bell"></i>
      <p>Task Reminder</p>
    </div>
    <i class="fa-solid fa-circle-xmark cursor" (click)="closereminder()"></i>
  </header>
  <div class="content" *ngFor="let data of notificationvalue; let i = index">
    <div class="content-items cursor" (click)="routetotask(data)">
      <p class="task-count"><i class="fa-solid fa-list-check"></i></p>
      <p class="task-content">
        {{ data.task_name }}
      </p>
    </div>
    <!-- <div class="content-items">
      <p class="task-count"><i class="fa-solid fa-list-check"></i></p>
      <p class="task-content">
        Lorem ipsum dolor sit amet conse ipsum dolor sit amet conse
      </p>
    </div> -->
  </div>
</div>
<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalentity">
  Launch demo modal
</button> -->

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModalentity"
  tabindex="-1"
  aria-labelledby="exampleModalLabelen"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabelen" style="font-family: Montserrat;font-size: 16px;">Entity</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form
          style="width: 10rem; font-family: Montserrat"
          [formGroup]="ReloadForm"
          (ngSubmit)="viewDetail_Entityreload()"
        >
          <!-- <h3 style="font-weight: bold;font-family: Montserrat;">Select Entity</h3> -->
          <mat-form-field
            appearance="fill"
            style="width: 300px; font-family: Montserrat;height: 70px;"
          >
            <mat-label>Choose Entity</mat-label>
            <mat-select formControlName="entity">
              <mat-option
                *ngFor="let list of entityList"
                (click)="select_entityName(list)"
                [value]="list.id"
                >{{ list.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="modal-footer">
        <span class="col-md-12 text-center" style="margin-bottom: 5%">
          <button
            type="submit"
            mat-raised-button
            style="background-color: #21cbe5; color: white;margin-left: 10px"
            data-bs-dismiss="modal"
            (click)="viewDetail_Entityreload()"
          >
            Submit
          </button>
          <button
            type="button"
            data-bs-dismiss="modal"
            mat-raised-button
            style="background-color: #555757; color: white;margin-left: 10px"
          >
            Close
          </button>
        </span>
        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
<!-- <div class="modal fade" id="entityreloadpopup" role="dialog">
  <div class="modal-dialog modal-sm" role="document" style="margin-right: 0%;">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">Entity</p>
        <button type="button" #closeentityreload class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form style="width: 10rem;font-family: Montserrat;" [formGroup]="ReloadForm" (ngSubmit)="viewDetail_Entityreload()">
          <mat-form-field appearance="fill" style="width: 250px;font-family: Montserrat;">
              <mat-label>Choose Entity</mat-label>
              <mat-select formControlName="entity">
                  <mat-option *ngFor="let list of entityList" (click)="select_entityName(list)" [value]="list.id">{{list.name}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          
        </form>
      <div class="modal-footer">
        <span class="col-md-12 text-center" style="margin-bottom: 5%;">
          <button type="submit" style="margin-left: 10px;" class="btn btn-light" id="primarybtn"
            (click)="viewDetail_Entityreload()">Submit</button>
          <button type="button" data-dismiss="modal" class="btn btn-light" id="secondarybtn"
            style="margin-left: 10px;">Close</button>
        </span>
      </div> 
     
      </div>
    </div>
  </div>
</div> -->
